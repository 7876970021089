@import url("https://fonts.googleapis.com/css?family=Montserrat|Source+Code+Pro|Londrina+Outline&display=swap");

body {
  background-image: url("./images/cocora-valley-bg-min.jpg");
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  /* background: url("./images/cocora-valley-bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

h1 {
  font-family: "Londrina Outline", cursive;
  color: #7baac1;
  font-size: 5em;
  font-weight: 800;
  letter-spacing: 25px;
  text-shadow: 2px 2px #ffffff;
  display: flex;
  justify-content: center;
}

p {
  font-family: "Montserrat", sans-serif;
}

.bold-text {
  font-family: "Source Code Pro", monospace;
  /* font-weight: bold; */
  color: black;
  font-size: 1.1em;
  text-align: center;
}

#main-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 12%;
  line-height: 30%;
  text-align: center;
}

#footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: right;
  font-size: 0.8em;
  color: black;
}

#footer-text {
  font-family: "Source Code Pro", monospace;
}

#icons {
  display: inline-block;
  padding-top: 1.5%;
  /* justify-content: space-evenly; */
}

a {
  text-decoration: none;
  color: #7baac1;
}

a:hover {
  color: #7baac1;
  opacity: 0.5;
  text-decoration: none;
}

/* MQ breakpoint */
@media only screen and (max-width: 850px) {
  #footer-text {
    padding-top: 5%;
  }

  .bold-text {
    line-height: 1.2;
  }
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  h1 {
    font-size: 3.5rem;
    color: black;
    text-shadow: 2px 2px #ffffff;
  }
  .bold-text {
    font-size: 0.9rem;
  }
  #footer {
    font-size: 0.6rem;
    text-align: center;
  }
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}
