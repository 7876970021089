@import url("https://fonts.googleapis.com/css?family=Montserrat|Source+Code+Pro&display=swap");

#navbar {
  /* margin-top: 10%; */
  display: flex;
  align-content: center;
}

ul {
  list-style-type: none;
  font-family: "Montserrat", sans-serif;
  line-height: 1.6;
}

#peace-sign {
  height: 100px;
  width: 100px;
}
