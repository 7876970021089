@import url(https://fonts.googleapis.com/css?family=Montserrat|Source+Code+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Source+Code+Pro|Londrina+Outline&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Source+Code+Pro|Londrina+Outline&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#navbar {
  /* margin-top: 10%; */
  display: flex;
  align-content: center;
}

ul {
  list-style-type: none;
  font-family: "Montserrat", sans-serif;
  line-height: 1.6;
}

#peace-sign {
  height: 100px;
  width: 100px;
}

body {
  background-image: url(/static/media/cocora-valley-bg-min.119d38b0.jpg);
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  /* background: url("./images/cocora-valley-bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

h1 {
  font-family: "Londrina Outline", cursive;
  color: #7baac1;
  font-size: 5em;
  font-weight: 800;
  letter-spacing: 25px;
  text-shadow: 2px 2px #ffffff;
  display: flex;
  justify-content: center;
}

p {
  font-family: "Montserrat", sans-serif;
}

.bold-text {
  font-family: "Source Code Pro", monospace;
  /* font-weight: bold; */
  color: black;
  font-size: 1.1em;
  text-align: center;
}

#main-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 12%;
  line-height: 30%;
  text-align: center;
}

#footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: right;
  font-size: 0.8em;
  color: black;
}

#footer-text {
  font-family: "Source Code Pro", monospace;
}

#icons {
  display: inline-block;
  padding-top: 1.5%;
  /* justify-content: space-evenly; */
}

a {
  text-decoration: none;
  color: #7baac1;
}

a:hover {
  color: #7baac1;
  opacity: 0.5;
  text-decoration: none;
}

/* MQ breakpoint */
@media only screen and (max-width: 850px) {
  #footer-text {
    padding-top: 5%;
  }

  .bold-text {
    line-height: 1.2;
  }
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  h1 {
    font-size: 3.5rem;
    color: black;
    text-shadow: 2px 2px #ffffff;
  }
  .bold-text {
    font-size: 0.9rem;
  }
  #footer {
    font-size: 0.6rem;
    text-align: center;
  }
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

#work-headline {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

h2 {
  font-family: "Londrina Outline", cursive;
  color: #7baac1;
  font-size: 3.5em;
  text-align: center;
  font-weight: 800;
  letter-spacing: 25px;
  text-shadow: 2px 2px #ffffff;
}

h5 {
  font-family: "Source Code Pro", monospace;
  color: #7baac1;
  font-weight: 700;
}

.card-titles {
  font-family: "Source Code Pro", monospace;
  font-weight: 600;
  font-size: 0.9em;
}

#card-pic-db {
  height: 400px;
  width: 400px;
}

#card-pic-projectify {
  height: 250px;
  width: 400px;
}

#devBeatz-card {
  padding-right: 5%;
}

.card-overall {
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  justify-content: center;
}

.card-details {
  background: #ededed;
  width: 400px;
  padding: 5px 5px 0 5px;
  /* opacity: 0.7; */
}

.card-body {
  font-weight: 100;
  font-size: 0.8em;
  padding: 0px;
}

#peaceSign {
  height: 65px;
  width: 65px;
}

/* MQ breakpoint */
@media only screen and (max-width: 850px) {
  .card-overall {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-content: center;
  }

  .card-details {
    padding: 0px;
  }

  #projectify-card {
    padding-bottom: 10%;
  }
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .card-body {
    font-size: 0.6rem;
    padding-left: 50px;
    padding-right: 50px;
  }

  .card-details {
    padding-left: 30px;
  }
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

