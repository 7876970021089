@import url("https://fonts.googleapis.com/css?family=Montserrat|Source+Code+Pro|Londrina+Outline&display=swap");

#work-headline {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

h2 {
  font-family: "Londrina Outline", cursive;
  color: #7baac1;
  font-size: 3.5em;
  text-align: center;
  font-weight: 800;
  letter-spacing: 25px;
  text-shadow: 2px 2px #ffffff;
}

h5 {
  font-family: "Source Code Pro", monospace;
  color: #7baac1;
  font-weight: 700;
}

.card-titles {
  font-family: "Source Code Pro", monospace;
  font-weight: 600;
  font-size: 0.9em;
}

#card-pic-db {
  height: 400px;
  width: 400px;
}

#card-pic-projectify {
  height: 250px;
  width: 400px;
}

#devBeatz-card {
  padding-right: 5%;
}

.card-overall {
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  justify-content: center;
}

.card-details {
  background: #ededed;
  width: 400px;
  padding: 5px 5px 0 5px;
  /* opacity: 0.7; */
}

.card-body {
  font-weight: 100;
  font-size: 0.8em;
  padding: 0px;
}

#peaceSign {
  height: 65px;
  width: 65px;
}

/* MQ breakpoint */
@media only screen and (max-width: 850px) {
  .card-overall {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-content: center;
  }

  .card-details {
    padding: 0px;
  }

  #projectify-card {
    padding-bottom: 10%;
  }
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .card-body {
    font-size: 0.6rem;
    padding-left: 50px;
    padding-right: 50px;
  }

  .card-details {
    padding-left: 30px;
  }
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}
